import { de } from 'date-fns/locale'

export const MAX_CHALLENGES = 6
export const ALERT_TIME_MS = 2000
export const LONG_ALERT_TIME_MS = 10000
export const REVEAL_TIME_MS = 350
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_INAPP_BROWSERS = true
export const ENABLE_MIGRATE_STATS = true
export const BLOWFISH_KEY = '7enXZKVUg-iKtnyNiRT4CG4awzhatk'
export const BLOWFISH_IV = 'AXjy_F*3'
export const ENABLE_ARCHIVED_GAMES = false
export const DATE_LOCALE = de
