export const WORDS = [
  'sharn',
  'quirm',
  'jingo',
  'brick',
  'twurp',
  'groat',
  'nanny',
  'swing',
  'susan',
  'khuft',
  'nobbs',
  'times',
  'thaum',
  'gogol',
  'witch',
  'demon',
  'pucci',
  'smale',
  'ahmed',
  'golem',
  'sonky',
  'guard',
  'elves',
  'buggy',
  'sarge',
  'teach',
  'death',
  'worde',
  'kring',
  'tulip',
  'colon',
  'tanty',
  'biers',
  'dwarf',
  'harry',
  'sybil',
  'drome',
  'nobby',
  'runes',
  'cohen',
  'inigo',
  'osric',
  'cuddy',
  'polly',
  'magic',
  'hiver',
  'tsort',
  'eight',
  'poons',
  'andre',
  'splot',
  'spike',
  'zlorf',
  'light',
  'shine',
  'lutze',
  'spell',
  'slant',
  'atuin',
  'shawn',
  'leshp',
  'agnes',
  'chalk',
  'igors',
  'skund',
  'anoia',
  'omnia',
  'krull',
  'beans',
  'ember',
  'wonse',
  'binky',
  'gavin',
  'jason',
  'sator',
  'rufus',
  'kelda',
  'dorfl',
  'abbot',
  'errol',
  'simon',
  'slake',
  'stone',
  'gnoll',
  'troll',
  'boffo',
  'endos',
  'keith',
  'genua',
  'cecil',
  'vimes',
  'slice',
  'truth',
  'druid',
  'knurd',
  'legba',
  'raven',
  'sheep',
  'perks',
  'quoth',
  'tubul',
  'terry',
  'sally',
  'nijel',
  'moist',
  'tphon',
  'scone',
  'twyla',
  'opera',
  'waily',
  'adora',
  'gryle',
  'watch',
  'grune',
  'caleb',
  'stamp',
  'gnome',
  'belle',
  'trunk',
  'gonne',
  'gytha',
  'piper',
  'guild',
  'angua',
]
